::-webkit-scrollbar {
  height: 7.5px;
  width: 7.5px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #34495ea6;
  border-radius: 8.5px;
}
